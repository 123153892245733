export default function useNavigation () {
  const { locale } = useI18n()

  const docs = {
    cs: {
      tou: '/docs/TOU_CZ.pdf',
      pp: '/docs/PP_CZ.pdf',
    },
    en: {
      tou: '/docs/TOU_EN.pdf',
      pp: '/docs/PP_EN.pdf',
    },
  }

  const docUrl = (type: 'tou' | 'pp') => {
    const currentLocale = (Object.keys(docs).includes(locale.value)) ? locale.value as keyof typeof docs : 'en'
    return docs[currentLocale][type]
  }

  const navigationItems = computed(() => [
    { key: 'nav.about', href: 'https://www.czkc.cz/', target: '_blank' },
    { key: 'nav.faucet', href: '#', target: '_self', isMobile: true },
    { key: 'nav.termsOfUse', href: docUrl('tou'), target: '_blank' },
    { key: 'nav.privacyPolicy', href: docUrl('pp'), target: '_blank' },
    { key: 'nav.docs', href: 'https://www.czkc.dev/', target: '_blank' },
  ])

  return { navigationItems }
}
