<script lang="ts" setup>
export type InputType = 'text' | 'number'

export interface InputProps {
  type: InputType
  name: string
  label?: string
  placeholder?: string
  isRequired?: boolean
  readOnly?: boolean
  modelValue?: any
  error?: string
  disabled?: boolean
  cypressPrefix?: string
}

const props = withDefaults(defineProps<InputProps>(), {
  type: 'text',
  disabled: false,
})

const emit = defineEmits(['update:modelValue', 'blur', 'subEvent'])

if (!props.name)
  throw createError(`name is required (${props.label})`)

const modelValue = useVModel(props, 'modelValue', emit)

const slots = useSlots()

const computedInputClass = computed(() => {
  return {
    'pr-73px overflow-hidden text-ellipsis': slots.suffix,
    'pr-97px overflow-hidden text-ellipsis input-base-error': slots.suffix && props.error,
    'input-base-error': props.error,
  }
})
</script>

<template>
  <div flex flex-col gap-2>
    <label
      :for="name"
      label-style
    >
      {{ label }}
      <span v-if="isRequired" text-app-error>*</span>
    </label>
    <div :class="error && 'relative'">
      <input
        :id="name"
        v-model="modelValue"
        :type="type"
        :name="name"
        :disabled="disabled"
        :readonly="readOnly"
        input-base
        :class="computedInputClass"
        :placeholder="placeholder"
      >
      <div
        v-if="error"
        pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3
      >
        <UnoIcon class="i-heroicons-exclamation-circle-16-solid size-5 text-app-error" aria-hidden="true" />
      </div>
      <div v-if="$slots.suffix" absolute right="10px sm:12px" mt="-34px sm:-38px" :class="[error ? 'right-34px sm:right-36px' : 'right-10px md:right-12px']">
        <slot name="suffix" />
      </div>
    </div>
    <p
      v-if="error"
      :id="`${name}-error`"
      text-sm text-app-error
    >
      {{ error }}
    </p>
  </div>
</template>

<style scoped>
input {
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type="date"]::-webkit-inner-spin-button,
  &[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
}
</style>
